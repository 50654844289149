import { validate as isValidUUID } from 'uuid';

/**
 * @type {import('@sveltejs/kit').ParamMatcher}
 *
 * @param {string} param
 * @returns {boolean}
 */
export function match(param) {
    return isValidUUID(param);
}
