/**
 * Check if a string is a valid user ID.
 *
 * @param {string} uid - The value to check
 * @returns {boolean} True if the value is a valid ObjectID, false otherwise
 */
export function match(uid) {
    const checkForHexRegExp = new RegExp('^[0-9a-zA-Z]{1,128}$');

    return checkForHexRegExp.test(uid);
}
