import * as Sentry from '@sentry/sveltekit';
import { PUBLIC_GCP_SERVICE_NAME, PUBLIC_GCP_SERVICE_VERSION } from '$env/static/public';

// If you don't want to use Session Replay, remove the `Replay` integration,
// `replaysSessionSampleRate` and `replaysOnErrorSampleRate` options.
Sentry.init({
    dsn: 'https://88e9b9e9e9847ee1e4d23b1cc7647202@o4507226940964864.ingest.de.sentry.io/4507226943717456',
    release: `${PUBLIC_GCP_SERVICE_NAME}@${PUBLIC_GCP_SERVICE_VERSION}`,
    environment: import.meta.env.VITE_NODE_ENV,
    enabled: import.meta.env.VITE_NODE_ENV === 'production',
    tracesSampleRate: 0.1,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1,
    integrations: [
        Sentry.replayIntegration(),
        Sentry.feedbackIntegration({
            // Additional SDK configuration goes in here, for example:
            colorScheme: 'system',
            formTitle: 'Send feedback',
            submitButtonLabel: 'Send feedback',
            messagePlaceholder: 'Help us improve by sending feedback! 🚀',
            successMessageText: 'Submitted feedback. Thank you! 🙏',
            buttonLabel: '',
        }),
        // Add browser profiling integration to the list of integrations
        Sentry.browserProfilingIntegration(),
    ],
});

export const handleError = Sentry.handleErrorWithSentry(async function _handleError({ error }) {
    console.error(error?.error?.message || error.toString());
});
