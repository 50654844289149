/**
 * Check if a string is a valid API client ID.
 *
 * @param {string} clientId - The value to check
 * @returns {boolean} True if the value is a valid ObjectID, false otherwise
 */
export function match(clientId) {
    const checkForHexRegExp = new RegExp('^[0-9a-z]{32}$');

    return checkForHexRegExp.test(clientId);
}
